import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice, getFirstPrice } from "../../helpers/product";
import ProductImageGallery from "../../components/product/ProductImageGallery";
import ProductDescriptionInfo from "../../components/product/ProductDescriptionInfo";
import ProductImageGallerySideThumb from "../../components/product/ProductImageGallerySideThumb";
import ProductImageFixed from "../../components/product/ProductImageFixed";
import { AuthContext } from "../../common/Auth";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";

const ProductImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  galleryType,
  product,
  currency,
  cartItems,
  wishlistItems,
  artworkList,
  compareItems,
  artworkListById,
  kitConfig,
  hideImage,
  isModal,
}) => {
  artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
  const [selectedColor, setSelectedColor] = useState(null)
  const [selectedThumbImgColor, setSelectedThumbImgColor] = useState(null)
  const [selectedDecorations, setSelectedDecorations] = useState(null)
  const [singleSelectedDecorations, setSingleSelectedDecorations] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [toggle, setToggle] = useState(false)
  const [isReplace, setIsReplace]=useState(true)
  const [hideImageState, setHideImage] = useState(false)

  useEffect(() => {
    setHideImage(hideImage);
  }, [hideImage]);

  const wishlistItem = wishlistItems?.filter(
    (wishlistItem) => wishlistItem.id === product.id,
  )[0]
  const compareItem = compareItems?.filter(
    (compareItem) => compareItem.id === product.id,
  )[0]
  const { addToast } = useToasts()
  let productPrice = product.price ? product.price : 0
  let productDiscount = product.discount ? product.discount : 0
  const discountedPrice = getDiscountPrice(productPrice, productDiscount)
  let finalProductPrice = Number(getFirstPrice(product, decimalsToShow))
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(decimalsToShow)
  const { userGroupDiscount, themeSettingsData } = useContext(AuthContext)
  const [decimalsToShow, setDecimalsToShow] = useState(2);
  const {
		productSettings,
	} = useThemeSettingsData({ themeSettingsData });
  useEffect(() => {
		if(!!productSettings?.decimalsToShow) {
			setDecimalsToShow(Number(productSettings?.decimalsToShow));
		}
	}, [productSettings])
  let showSell = false
  let showSellPrice = false
  let offerPrice = 0
  let discountType = ''

  let todayDate = new Date().toISOString()

  if (todayDate >= product?.startDate && todayDate <= product?.endDate) {
    if (product?.makeProductSale) {
      showSell = true
    }
  } else if (product?.startDate == '' || product?.startDate == undefined) {
    if (product.makeProductSale) {
      showSell = true
    }
  }

  if (
    product?.showPriceBreak &&
    todayDate >= product?.startDate &&
    todayDate <= product?.endDate
  ) {
    showSellPrice = true
  }
  if (product?.sellingPrice) {
    offerPrice = product.sellingPrice
  }
  if (product?.discountType == '0' || product?.discountType == '1') {
    discountType = product?.discountType
  }

  let finalProductDiscPrice = 0

  if (Number(userGroupDiscount > 0)) {
    finalProductPrice =
      Number(finalProductPrice) -
      (Number(finalProductPrice) * Number(userGroupDiscount)) / 100
  }

  if (discountType == '1') {
    if (offerPrice > 0) {
      finalProductDiscPrice = Number(finalProductPrice) - Number(offerPrice)
    }
  } else if (discountType == '0') {
    if (offerPrice > 0) {
      finalProductDiscPrice =
        Number(finalProductPrice) -
        (Number(finalProductPrice) * Number(offerPrice)) / 100
    }
  }
  const [tempValue, setTempValue] = useState(0)
  const tempfun = () => {
    setTempValue(tempValue + 1)
  }

  return (
    <div
      className={`shop-area ${spaceTopClass ? spaceTopClass : ''} ${
        spaceBottomClass ? spaceBottomClass : ''
      }`}
    >
      <div className="pl-2 pr-2">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            {/* product image gallery */}
            {hideImageState ? (<></>
            ) : galleryType === 'leftThumb' ? (
              <ProductImageGallerySideThumb
                product={product}
                thumbPosition="left"
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                artworkListById={artworkListById}
                selectedDecorations={selectedDecorations}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                singleSelectedDecorations={singleSelectedDecorations}
                setSelectedThumbImgColor={setSelectedThumbImgColor}
                selectedThumbImgColor={selectedThumbImgColor}
                isReplace={isReplace}
                setIsReplace={setIsReplace}
              />
            ) : galleryType === 'rightThumb' ? (
              <ProductImageGallerySideThumb
                product={product}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                artworkListById={artworkListById}
                selectedDecorations={selectedDecorations}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                singleSelectedDecorations={singleSelectedDecorations}
                setSelectedThumbImgColor={setSelectedThumbImgColor}
                selectedThumbImgColor={selectedThumbImgColor}
                isReplace={isReplace}
                setIsReplace={setIsReplace}
              />
            ) : galleryType === 'fixedImage' ? (
              <ProductImageFixed
                product={product}
                selectedColor={selectedColor}
                artworkList={artworkList}
                artworkListById={artworkListById}
                selectedDecorations={selectedDecorations}
                selectedImage={selectedImage}
                singleSelectedDecorations={singleSelectedDecorations}
                setSelectedThumbImgColor={setSelectedThumbImgColor}
                selectedThumbImgColor={selectedThumbImgColor}
                isReplace={isReplace}
                setIsReplace={setIsReplace}
              />
            ) : (
              <ProductImageGallery
                product={product}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                artworkList={artworkList}
                selectedDecorations={selectedDecorations}
                setSelectedImage={setSelectedImage}
                showSell={showSell}
                showSellPrice={showSellPrice}
                offerPrice={offerPrice}
                discountType={discountType}
                finalProductDiscPrice={finalProductDiscPrice}
                selectedImage={selectedImage}
                artworkListById={artworkListById}
                singleSelectedDecorations={singleSelectedDecorations}
                setSelectedThumbImgColor={setSelectedThumbImgColor}
                selectedThumbImgColor={selectedThumbImgColor}
                isReplace={isReplace}
                setIsReplace={setIsReplace}
              />
            )}
          </div>
          <div className="col-lg-7 col-md-6">
            {/* product description info */}
            <ProductDescriptionInfo
              product={product}
              setSelectedColor={setSelectedColor}
              setSelectedThumbImgColor={setSelectedThumbImgColor}
              selectedColor={selectedColor}
              setToggle={setToggle}
              toggle={toggle}
              setSelectedDecorations={setSelectedDecorations}
              discountedPrice={discountedPrice}
              currency={currency}
              finalDiscountedPrice={finalDiscountedPrice}
              finalProductPrice={finalProductPrice}
              cartItems={cartItems}
              wishlistItem={wishlistItem}
              compareItem={compareItem}
              addToast={addToast}
              showSell={showSell}
              showSellPrice={showSellPrice}
              offerPrice={offerPrice}
              discountType={discountType}
              finalProductDiscPrice={finalProductDiscPrice}
              tempfun={tempfun}
              setSingleSelectedDecorations={setSingleSelectedDecorations}
              singleSelectedDecorations={singleSelectedDecorations}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
              isReplace={isReplace}
              setIsReplace={setIsReplace}
              kitConfig={kitConfig}
              isModal={isModal}
              setHideImage={setHideImage}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

ProductImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData.cartItems,
    wishlistItems: state.wishlistData,
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    compareItems: state.compareData
  };
};

export default connect(mapStateToProps)(ProductImageDescription);
