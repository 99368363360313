export const firstLetterUpper = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
}

export const getMainImageUrls = (product) => {
  let primaryImage = ""
  let secondaryImage = ""
  if (product && product.MediaContent) {
    product.MediaContent.map(mediaArray => {
      if (mediaArray.primary) {
        primaryImage = mediaArray.url;
      } else if (mediaArray.secondary) {
        secondaryImage = mediaArray.url;
      }
    })
    if (primaryImage == "" && product.MediaContent.length > 0) {
      primaryImage = product.MediaContent[0].url
    }
  }
  return { primaryImage, secondaryImage }
}

export const productImageUrl = (imgUrl) => {
  let newUrl = replaceOrigin(imgUrl);
  if (newUrl) {
    if (typeof newUrl === 'string') {
      newUrl = newUrl.replace('cdnl.sanmar.com', 'cdnm.sanmar.com');
    } else if (typeof newUrl === 'object' && newUrl instanceof Array) {
      newUrl.forEach(url => {
        url = url.replace('cdnl.sanmar.com', 'cdnm.sanmar.com');
      });
    }
  }
  if (typeof newUrl === 'string' && newUrl.includes('cdnm.sanmar.com')) {
    const operations = encodeURI(
      JSON.stringify([
        {
          operation: 'thumbnail',
          params: {
            width: 300,
            background: '255,255,255',
            type: 'jpeg',
            quality: 100,
          },
        },
        {
          operation: 'convert',
          params: {
            type: 'jpeg',
            quality: 100,
            width: 600,
          },
        },
      ]),
    )
    return (newUrl = `https://h4x0rt3hp74n37L4vv7.anterasaas.com/api/v1/imaginary/pipeline?url=${encodeURIComponent(
      newUrl,
    )}&operations=${operations}`)
  } else {
    return newUrl
  }
}
export const productImageExt = (imge) => {
  if (!!imge) {
    let ext = ''
    if (typeof imge !== 'string' && !!imge?.length && imge.length > 0) {
      ext = imge[0].split('.').pop().split(/\#|\?/)[0];
    }
    else if (typeof imge === 'string') {
      ext = imge.split('.').pop().split(/\#|\?/)[0];
    }
    if (ext === 'png')
      return true
    else
      return false
  }
  else
    return false
}

export const replaceOrigin = (url) => {
  if (url) {
    if (typeof url === 'string') {
      url = url.replace('cdnl.sanmar.com', 'cdnm.sanmar.com');
    } else if (typeof url === 'object' && url instanceof Array) {
      url.forEach(row => {
        row = row.replace('cdnl.sanmar.com', 'cdnm.sanmar.com');
      });
    }
  }
  return url;
}

function getRgbObj(color) {
  var rgb = [];
  if (color.indexOf('rgb') >= 0 || color.indexOf(',') >= 0) {
    rgb = color.split(',');
    rgb.forEach(function (d, i) {
      rgb[i] = d.replace(/\D/g, '');
    })
  } else {
    rgb = getRgbFromHex(color);
  }
  return rgb;
}
function getRgbFromHex(hex) {
  var rgb = [];

  //validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  for (let i = 0; i < 3; i++) {
    let c = parseInt(hex.substr(i * 2, 2), 16);
    rgb.push(c);
  }
  return rgb;
}
export const getBrightnessFromColor = (color) => {
  var brightness = 0;
  var rgb = getRgbObj(color);

  //brightness  =  sqrt( .299 R2 + .587 G2 + .114 B2 )
  brightness = Math.sqrt((.299 * (rgb[0] * rgb[0])) + (.587 * (rgb[1] * rgb[1])) + (.114 * (rgb[2] * rgb[2])));

  //convert to 0-100 scale
  brightness = Math.round(brightness / 255 * 100);

  return brightness;
}

export const getErrorMessageForQuickBookPopup = (product, RequiredDecorationForCheckOutPro) => {
  let blankTest = []
  let message = ''
  if (!!product.personalizationArray) {
    blankTest = !!product?.personalizationArray ? product.personalizationArray.filter(x => x.displayText === '') : []
  }

  if ((product?.WebstoreProductPartArray && product?.WebstoreProductPartArray?.length > 1 ? (product?.WebstoreProductPartArray) : (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart?.length > 1))) {
    message = 'Please select color and size'
    return { 'message': message, 'type': false }
  } else if ((RequiredDecorationForCheckOutPro === true || product?.IsRequiredDecorationForCheckOutPro === true) && !((!!product?.selectedProductDecorations && product.selectedProductDecorations.length > 0))) {
    message = 'Please choose a decoration to add this product to your cart'
    return { 'message': message, 'type': false }
  } else if (blankTest.length > 0) {
    message = 'You have added personalization to this product, but you haven’t added your text. Please add your text or delete Personalization to add this product to your cart.'
    return { 'message': message, 'type': false }
  } else {
    return { 'message': '', 'type': true }
  }
}

export const productPath = (product) => {
  const name = product.productName;
  const id = product.id;
  const str = `${id} ${name}`
  let formattedStr = str.replace(/[^a-zA-Z0-9]/g, '-');
  formattedStr = formattedStr.replace(/-+/g, '-');
  formattedStr = formattedStr.replace(/^-+|-+$/g, '');
  if (formattedStr.endsWith('-')) {
    formattedStr = formattedStr.slice(0, -1);
  }
  return formattedStr.toLowerCase();
}
export const findSizeList = (data) => {
  const dataRegex = /"data":\s*{\s*("[^"]*":\s*"[^"]*"\s*,?\s*)+}/;
  const valuesRegex = /"([^"]*)":\s*"[^"]*"/g;
  const match = data.match(dataRegex);
  const valuesString = match ? match[0] : '';
  if (!valuesString) {
    return [];
  }
  const values = valuesString.match(valuesRegex);
  return values ? values.map(entry => entry.match(/"([^"]+)":"[^"]*"/)[1]) : [];
};

export const getDecoChargeArrayByQty = (artworkList, decorationsArray, qty) => {
  let decoCharges = []
  for (const decoration of decorationsArray ?? []) {
    if (decoration?.decoPricedetails?.length > 0) {
      const artwork = artworkList.filter(
        (artwork) => artwork.id === decoration.artworkID,
      )[0]
      for (const decoPriceDetail of decoration.decoPricedetails ?? []) {
        let stitchCount = artwork?.design?.stitchCount
        if (stitchCount === '' || !Number(stitchCount)) {
          stitchCount = 0
        }
        if (
          Number(stitchCount) >= Number(decoPriceDetail.stitchesStart) &&
          Number(stitchCount) <= Number(decoPriceDetail.stitchesUpto) &&
          Number(qty !== '' ? qty : 1) >= Number(decoPriceDetail.qunatityStart) &&
          Number(qty !== '' ? qty : 1) <= Number(decoPriceDetail.quantityUpto)
        ) {
          decoCharges.push(decoPriceDetail);
          break;
        }
      }
    }
  }
  return decoCharges;
}

export const getDecoChargeByDecorationQty = (artwork, decoration, qty) => {
  if (decoration?.decoPricedetails?.length > 0) {
    for (const decoPriceDetail of decoration.decoPricedetails ?? []) {
      let stitchCount = artwork?.design?.stitchCount
      if (stitchCount === '' || !Number(stitchCount)) {
        stitchCount = 0
      }
      if (
        Number(stitchCount) >= Number(decoPriceDetail.stitchesStart) &&
        Number(stitchCount) <= Number(decoPriceDetail.stitchesUpto) &&
        Number(qty !== '' ? qty : 1) >= Number(decoPriceDetail.qunatityStart) &&
        Number(qty !== '' ? qty : 1) <= Number(decoPriceDetail.quantityUpto)
      ) {
        return decoPriceDetail;
      }
    }
  }
  return null;
}

export const getRawCartItemPriceData = (cartItem, artworkList, productSettings, rowDataIndex = 0) => {
  let isPriceBreak = cartItem.product?.isPriceBreak ? (cartItem?.doNotCalculateAtOrder == "1" ? false : true) : false
  let rowTotal = 0;
  let decoTotal = 0;
  let personalizationUnitPriceTotal = 0;
  let personalizationSetupPriceTotal = 0;
  let totalAdditionalCharges = 0;
  let totalSetupPrice = 0;
  let customDecorationPerUnitPrice = cartItem?.product?.customDecorationPerUnitPrice ?? 0;
  let customDecorationSetupFee = cartItem?.product?.customDecorationSetupFee ?? 0;
  let rowData = cartItem.currentOrder[rowDataIndex] ?? [];
  let totalSumQty = 0;
  let productId = cartItem?.product?.id;
  let product = cartItem?.product ?? {};
  let allowCustomDecoration = false;
  let customDecorationFee = cartItem?.product?.customDecorationFee;
  if(typeof cartItem?.product?.allowCustomDecoration === 'boolean') {
    allowCustomDecoration = cartItem?.product?.allowCustomDecoration
  } else {
    allowCustomDecoration = !!productSettings?.allowCustomDecoration ? productSettings.allowCustomDecoration : false
  }

  // Calculate values required for all rows
  for (const row of rowData) {
    totalSumQty += Number(row.quantity);
  }

  let setupMap = {};
  let chargeIdSet = new Set();
  for (const row of rowData) {
    let artwork = null
    if (Number(row.quantity) > 0) {
      for (const decoration of row.decorations) {
        artwork = artworkList.find(
          (artworkArray) => (artworkArray.id === decoration.artworkID),
        )
        const decoCharge = getDecoChargeByDecorationQty(artwork, decoration, isPriceBreak ? totalSumQty : Number(row.quantity));
        if (artwork && artwork.multipleProductsToDecorationMapping && artwork.multipleProductsToDecorationMapping[productId]) {
          const productToDecorationMapping = artwork.multipleProductsToDecorationMapping[productId]
          productToDecorationMapping.filter(x => x.locationName === decoration.location).forEach((productMap) => {
            productMap.charges.forEach((charge) => {
              if (!chargeIdSet.has(charge.chargeCode)) {
                const sortedCharges = charge.ChargePriceArray?.ChargePrice?.sort((a, b) => Number(a.xMinQty) - Number(b.xMinQty));
                const chargePrice = sortedCharges?.findLast(x => Number(x.xMinQty) <= totalSumQty);
                if (chargePrice) {              
                  const price = (Number(chargePrice?.salePrice) ?? 0) * (charge?.matchOrderQty === "1" ? totalSumQty : 1);    
                  totalAdditionalCharges += price;
                  chargeIdSet.add(charge.chargeCode);
                }
              }
            })
          })
        }
        const customArtwork = artwork?.artworkSource == 'aether' && customDecorationFee && allowCustomDecoration;
        if (!setupMap?.[decoration.locationId]?.[decoration.artworkID]) {
          if (customArtwork) {
            totalAdditionalCharges += customDecorationSetupFee ? Number(customDecorationSetupFee) : Number(0);
          } else {
            totalAdditionalCharges += decoCharge?.setupChargeSalePrice ? Number(decoCharge.setupChargeSalePrice) : Number(0);
          }
          if (!setupMap[decoration.locationId]) {
            setupMap[decoration.locationId] = {};
          }
          setupMap[decoration.locationId][decoration.artworkID] = true;
        }
        if (customArtwork) {
          decoTotal += Number(customDecorationPerUnitPrice ?? 0) * Number(row.quantity);
        } else {
          decoTotal += Number(decoCharge?.salePrice ?? 0) * Number(row.quantity);
        }
      }
    }

    let personalizationCostData = row.personalizationCost;
    let personalizationCost = 0;
    let personalizationSetupCost = 0;

    if (personalizationCostData) {
      personalizationSetupCost = personalizationCostData.setupPrice ?? 0;
      if (personalizationCostData.matchOrderQty == 0) {
        personalizationCost = personalizationCostData.price ?? 0;
      } else if (personalizationCostData?.matchOrderQty == 1) {
        // should this be total sum qty? test
        personalizationCost = Number((personalizationCostData.price ?? 0) * totalSumQty)
      }
    }
    personalizationUnitPriceTotal = Number(personalizationUnitPriceTotal) + Number(personalizationCost);
    personalizationSetupPriceTotal = Number(personalizationSetupPriceTotal) + Number(personalizationSetupCost);
    let mainPrice = Number(row.quantity) * Number(row.price)
    if (product?.doNotCalculateAtOrder == "1" && product?.isRestrictQty) {
      mainPrice = Number(row.price)
    }
    rowTotal += mainPrice;
  }

  if(product?.ChargeArray?.Charge?.length > 0) {
    product.ChargeArray.Charge.forEach((charge) => {
      if(!chargeIdSet.has(charge.chargeCode)) {
        const sortedCharges = charge.ChargePriceArray?.ChargePrice?.sort((a, b) => Number(a.xMinQty) - Number(b.xMinQty));
        const chargePrice = sortedCharges?.findLast(x => Number(x.xMinQty) <= totalSumQty);
        if(chargePrice) {
          const price = (Number(chargePrice?.salePrice) ?? 0) * (charge?.matchOrderQty === "1" ? totalSumQty : 1);
          totalAdditionalCharges += price;
          chargeIdSet.add(charge.chargeCode);
        }
      }
    })
  }

  return {
    rowTotal,
    decoTotal,
    personalizationUnitPriceTotal,
    personalizationSetupPriceTotal,
    // Note total additional charges doesn't include personalization charges
    totalAdditionalCharges
  }
}

export const getCartItemTotal = (cartItem, artworkList, productSettings, rowDataIndex = 0) => {
  let {
    rowTotal,
    decoTotal,
    personalizationUnitPriceTotal,
    personalizationSetupPriceTotal,
    totalAdditionalCharges
  } = getRawCartItemPriceData(cartItem, artworkList, productSettings, rowDataIndex);
  return Number(rowTotal) + Number(decoTotal) + Number(personalizationUnitPriceTotal) + Number(personalizationSetupPriceTotal) + Number(totalAdditionalCharges);
}

export const getCartItemDisplayTotal = (cartItem, artworkList, productSettings, rowDataIndex = 0) => {
  let {
    rowTotal,
    decoTotal,
    personalizationUnitPriceTotal,
    personalizationSetupPriceTotal,
    totalAdditionalCharges
  } = getRawCartItemPriceData(cartItem, artworkList, productSettings, rowDataIndex);
  const product = cartItem.product;
  
  if (cartItem.isKitChildProduct && cartItem.kitPriceMode === 'kit') {
    return 0;
  }

  // Show Price
  let showPrice = true;
  if (typeof product.showPriceInCart === 'boolean') {
    showPrice = product.showProductPrice
  }
  
  if (!showPrice) {
    return 0;
  }

  // Show Deco Charges
  let showDecoCharges = false;
  if (typeof product.showDecorationCostInCart === 'boolean') {
    showDecoCharges = product.showDecorationCostInCart
  } else {
    showDecoCharges = productSettings.showDecorationCostInCart;
  }

  // Show Personalization Cost
  let showPersonalizationCost = false;
  if (typeof product.showPersonalizationCostInCart === 'boolean') {
    showPersonalizationCost = product?.showPersonalizationCostInCart;
  } else {
    showPersonalizationCost = productSettings.showPersonalizationCostInCart;
  }

  // Show Set Up Charges
  let showSetupCharges = false;
  if (typeof product.showSetupChargeInCart === 'boolean') {
    showSetupCharges = product.showSetupChargeInCart;
  } else {
    showSetupCharges = productSettings.showSetupChargeInCart;
  }

  // Decimals to show
  let decimalsToShowProduct = 2
  if (!!product?.decimalsToShow) {
    decimalsToShowProduct = Number(product.decimalsToShow);
  } else if (productSettings?.decimalsToShow) {
    decimalsToShowProduct = productSettings.decimalsToShow;
  }

  
  let cartItemTotal =
    Number(decoTotal) +
    Number(personalizationUnitPriceTotal) +
    Number(rowTotal);
  if (showSetupCharges) {
    cartItemTotal += Number(totalAdditionalCharges);
    cartItemTotal += Number(personalizationSetupPriceTotal);
  }
  return cartItemTotal;
}

export function getHtmlTextElement(html) {
  const tmp = document.createElement('div')
  tmp.innerHTML = html

  return tmp.textContent || tmp.innerText;
}