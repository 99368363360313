import React, {useEffect, useState} from "react"
import { VIVA_API_URL } from "../../common/ActionUrl";

export const useThemeSettings = (props) => {
    const {settingData} = props
    const [isPublic, setIsPublic] = useState();
    const [storeName, setStoreName] = useState('')
    const [orderNotePlaceHolder, setOrderNotePlaceHolder] = useState('Notes about your order, e.g. special notes for delivery.')
    const [priceDisplay, setPriceDisplay] = useState(false)
    const [itempriceDisplay, setItemPriceDisplay] = useState(false)
    const [isRequireCostCenter, setisRequireCostCenter] = useState(false)
    const [IsRequiredCostCenter, setIsRequiredCostCenter] = useState(false)
    const [disabledOrderSettings, setDisabledOrderSettings] = useState(true)
    const [documentCheckout, setDocumentCheckout] = useState(null)
    const [defaultCountry, setDefaultCountry] = useState('')
    const [textUploadDoc, setTextUploadDoc] = useState(null)
    const [registrationRequired, setRegistrationRequired] = useState(false)
    const [registrationRequiredOptional, setregistrationRequiredOptional] = useState(false)
    const [isRequireCompanyname, setIsRequireCompanyname] = useState(false)
    const [productLayoutSettings, setProductLayoutSettings] = useState(false)
    const [isRequirePhoneNumber, setIsRequirePhoneNumber] = useState(false)
    const [renameCompany, setRenameCompany] = useState(null)
    const [isBillingSuppress, setIsBillingSuppress] = useState(false)
    const [selectShippingAddress, setSelectShippingAddress] = useState('')
    const [selectBillingAddress, setSelectBillingAddress] = useState('')
    const [checkoutSettings, setCheckoutSettings] = useState('')
    const [subTotalFontColor, setSubTotalFontColor] = useState('')
    const [displayLocation, setDisplayLocation] = useState('displayLocationAddress')
    const [lockCountryEnabled, setLockCountryEnabled] = useState(false)
    const [lockCountry, setLockCountry] = useState('US')
    const [lockUserDataFields, setLockUserDataFields] = useState(new Set())
    const [lockDefaultAddress, setlockDefaultAddress] = useState(false)
    const [itemNumber, setItemValue] = useState('')
    const [themedefaultColor, setThemedefaultColor] = useState('#FF7F00')
    const [themedefaultTextColor, setThemedefaultTextColor] = useState('#333333')
    const [themedefaulthoverColor, setThemedefaulthoverColor] = useState('#000')
    const [showCompareToggle, setShowCompareToggle] = useState(false);
    const [showWishListToggle, setShowWishListToggle] = useState(false);
    const [isShowMyAccount, setIsShowAccount] = useState(null);
    const [showAddToCartOnCat, setShowAddToCartOnCat] = useState(true);
    const [showAddToCartOnProd, setShowAddToCartOnProd] = useState(true);
    const [showOrderModuleInAccount, setShowOrderModuleInAccount] = useState(false);
    const [showLogo, setLogoShow] = useState(false);
    const [logoWidth, setLogoWidth] = useState('');
    const [isordermoduleEnabled, setIsordermoduleEnabled] = useState(false);
    const [requireRegistration, setRequireRegistration] = useState(false)
    const [daysTopurgeCartitem, setdaysTopurgeCartitem] = useState(false)
    const [clrCartButtonText, setClrCartButtonText] = useState('')
    const [showClrCartButtonText, setShowClrCartButtonText] = useState('')
    const [proceedCartText, setProceedCartText] = useState(null)
    const [productCommentInCart, setProductCommentInCart] = useState(false)
    const [productCommentInCartLabel, setProductCommentInCartLabel] = useState(null)
    const [shoppingCartText, setShoppingCartText] = useState(null)
    const [subTotalCartFontColor, setSubTotalCartFontColor] = useState('')
    const [DefaultProductBackgroundColor, setDefaultProductBackgroundColor] = useState('')
    const [breadCrumbHoverColor, setBreadCrumbHoverColor] = useState('')
    const [artworkSetting, setArtworkSetting] = useState(null)
    const [generalSettings, setGeneralSettings] = useState(null)
    const [title, setTitle] = useState({});
    const [defaultApproval, setDefaultOrderApproval] = useState('');
    const [allowChangeOrderApproval, setAllowChangeOrderApproval] = useState(false);
    const [cartBubbleQuantity, setCartBubbleQuantity] = useState(false);
    const [cartBubbleLineItems, setCartBubbleLineItems] = useState(false);
    const [expandSearchBarUnderHeaderMenu, setExpandSearchBarUnderHeaderMenu] = useState(false);
    const [showSearchIcon, setShowSearchIcon] = useState(true);
    const [isShowSearchAccount, setIsShowSearchAccount] = useState(true);
    const [showQuickView, setShowQuickView] = useState(true)
    const [showAddToCartOnRelatedProd, setShowAddToCartOnRelatedProd] = useState(false);
    const [showQuickViewRelatedProduct, setShowQuickViewRelatedProduct] = useState(false);
    const [showWishListForRelatedProduct, setShowWishListForRelatedProduct] = useState(false);
    const [defaultFont, setDefaultFont] = useState('');
    const [googleMeasurementId, setGoogleMeasurementId] = useState('')
    const [googleTagManagerId, setGoogleTagManagerId] = useState('')
    const [showSecondaryCostCenter, setShowSecondaryCostCenter] = useState(false)
    const [requireSecondaryCostCenter, setRequireSecondaryCostCenter] = useState(false)
    const [secondaryCostCenterSeparator, setSecondaryCostCenterSeparator] = useState('')
    const [vivaUrl, setVivaUrl] = useState("")
    const [vivaSessionTime, setVivaSessionTime] = useState(2)
    const [secondaryCostCenterLabel, setSecondaryCostCenterLabel] = useState('')
    const [showFullListSecondaryCostCenter, setShowFullListSecondaryCostCenter] = useState(false)
    const [sortSizeSetting, setSortSizeSetting] = useState([])
    const [costCenterLabel, setCostCenterLabel] = useState('')
    const [ShowOrder, setShowOrder] = useState(true);
    const [ShowQuotes, setShowQuotes] = useState(true);
    const [showOrderToApprove, setShowOrderToApprove] = useState(true);
    const [paymentStatuses, setPaymentStatuses] = useState([])

    useEffect(() => {
        if (settingData && settingData.length > 0) {
            const generalSettings = settingData.find(x => x.page === 'General')
            const productLayoutData = settingData.find(x => x.page === "productLayoutSettings")
            setProductLayoutSettings(productLayoutData?.values)
            setGeneralSettings(generalSettings)
            setIsPublic(generalSettings?.values?.isPublic)
            setExpandSearchBarUnderHeaderMenu(generalSettings?.values?.expandSearchBarUnderHeaderMenu)
            setShowSearchIcon(generalSettings?.values?.isShowSearchAccount)
            setIsShowSearchAccount(generalSettings?.values?.isShowSearchAccount === false ? false : true)
            setStoreName(generalSettings?.values.storeName)
            setPriceDisplay(generalSettings?.values?.isDisplayPrice)
            setItemPriceDisplay(generalSettings?.values?.showItemPrice)
            setisRequireCostCenter(generalSettings?.values?.isRequireCostCenter)
            setIsRequiredCostCenter(generalSettings?.values?.IsRequiredCostCenter)
            setDisabledOrderSettings(generalSettings?.values?.disabledOrderSettings)
            setDocumentCheckout(generalSettings?.values?.isdocumentCheckout)
            setDefaultCountry(generalSettings?.values?.defaultCountry)
            setTextUploadDoc(generalSettings?.values?.textUploadDoc)
            setRegistrationRequired(generalSettings?.values?.registrationCheckoutRequired !== undefined ? generalSettings?.values?.registrationCheckoutRequired : false)
            setregistrationRequiredOptional(generalSettings?.values?.registrationCheckoutOptional !== undefined ? generalSettings?.values?.registrationCheckoutOptional : false)
            setShowCompareToggle(generalSettings.values.showCompare ?? false)
            setShowWishListToggle(generalSettings.values.showWishList ?? false)
            setShowOrderModuleInAccount(generalSettings?.values?.showOrderModuleInAccount ?? false)
            setLogoShow(generalSettings?.values?.showLogo)
            setLogoWidth(generalSettings?.values?.logoWidth)
            setIsordermoduleEnabled(generalSettings?.values?.showOrderModule ?? false)
            setTitle(generalSettings?.values?.tabName);
            setShowAddToCartOnRelatedProd(generalSettings?.values?.showAddToCartOnRelatedProd)
            setShowQuickViewRelatedProduct(generalSettings?.values?.showQuickViewRelatedProduct)
            setShowWishListForRelatedProduct(generalSettings?.values?.showWishListForRelatedProduct)
            setDefaultFont(generalSettings?.values?.defaultFont)
            if (!generalSettings?.values?.isPublic && generalSettings?.values?.isRequireRegistration) {
                setRequireRegistration(generalSettings.values.isRequireRegistration)
            }
            if (generalSettings?.values?.checkoutNoteDesc !== '' && generalSettings?.values?.checkoutNoteDesc !== undefined) {
                setOrderNotePlaceHolder(generalSettings?.values?.checkoutNoteDesc)
            }
            if (generalSettings?.values?.itemNumberToShowAtwebstore) {
                if (generalSettings.values.itemNumberToShowAtwebstore == 'productId') {
                    setItemValue('productId')
                } else if (generalSettings.values.itemNumberToShowAtwebstore == 'inhouseId') {
                    setItemValue('inhouseId')
                } else {
                    setItemValue('id')
                }

            }
            if (generalSettings?.values?.themedefaultColor) {
                setThemedefaultColor(generalSettings.values.themedefaultColor)
            }
            if (generalSettings?.values?.themedefaulttextColor) {
                setThemedefaultTextColor(generalSettings.values.themedefaulttextColor)
            }
            if (generalSettings?.values?.themedefaulthoverColor) {
                setThemedefaulthoverColor(generalSettings.values.themedefaulthoverColor)
            }
            if (generalSettings?.values?.isShowMyAccount === false) {
                setIsShowAccount(false)
            } else {
                setIsShowAccount(true)
            }
            if (generalSettings?.values?.showAddToCartOnCat === false) {
                setShowAddToCartOnCat(generalSettings.values.showAddToCartOnCat)
            }
            if (generalSettings?.values?.showAddToCartOnProd === false) {
                setShowAddToCartOnProd(generalSettings.values.showAddToCartOnProd)
            }
            if (!!generalSettings?.values?.DefaultProductBackgroundColor) {
                setDefaultProductBackgroundColor(generalSettings?.values?.DefaultProductBackgroundColor);
            }

            if ( generalSettings?.values?.breadCrumbHoverColor ) {
                setBreadCrumbHoverColor(generalSettings.values.breadCrumbHoverColor)
            }
            if (generalSettings?.values) {
                setShowQuickView(generalSettings.values.showQuickView)
            }

            setGoogleMeasurementId(generalSettings?.values?.googleMeasurementId || "")
            setGoogleTagManagerId(generalSettings?.values?.googleTagManagerId || "")
            setShowSecondaryCostCenter(generalSettings?.values?.showSecondaryCostCenter || false)
            setRequireSecondaryCostCenter(generalSettings?.values?.requireSecondaryCostCenter || false)
            setSecondaryCostCenterSeparator(generalSettings?.values?.secondaryCostCenterSeparator || '')
            setCostCenterLabel(generalSettings?.values?.costCenterLabel || '')
            setSecondaryCostCenterLabel(generalSettings?.values?.secondaryCostCenterLabel || '')
            setShowFullListSecondaryCostCenter(generalSettings?.values?.showFullListSecondaryCostCenter || false)
            const sortSizeSettings = settingData.find(x => x.settingsName === "SortSizeSetting");
            setSortSizeSetting(sortSizeSettings?.values ? sortSizeSettings.values : [])

            const checkoutSettings = settingData.find(x => x.page === 'checkoutSettings')
            setIsRequireCompanyname(checkoutSettings?.values?.isRequireCompanyname)
            setIsRequirePhoneNumber(checkoutSettings?.values?.isRequirePhoneNumber)
            setRenameCompany(checkoutSettings?.values?.renameCompany)
            setIsBillingSuppress(checkoutSettings?.values?.isBillingSuppress)
            setSelectShippingAddress(checkoutSettings?.values?.selectShippingAddress)
            setSelectBillingAddress(checkoutSettings?.values?.selectBillingAddress)
            setSubTotalFontColor(checkoutSettings?.values?.subTotalFontColor)
            setDisplayLocation(checkoutSettings?.values?.selectDisplayLocation)
            setLockCountryEnabled(checkoutSettings?.values?.lockCountryEnabled ?? false)
            setLockUserDataFields(new Set(checkoutSettings?.values?.lockUserDataFields ?? []))
            setLockCountry(checkoutSettings?.values?.lockCountry ?? 'US')
            if (!!checkoutSettings?.values?.lockDefaultAddress) {
                setlockDefaultAddress(checkoutSettings?.values?.lockDefaultAddress)
            }
            if (!!checkoutSettings?.values) {
                setCheckoutSettings(checkoutSettings?.values)
            }

            const cartSettings = settingData.find(x => x.page === 'cartSettings')
            setClrCartButtonText(cartSettings?.values?.clrCartButtonText)
            setShowClrCartButtonText(cartSettings?.values?.showClrCartButtonText)
            setProceedCartText(cartSettings?.values?.proceedCartText)
            setProductCommentInCart(cartSettings?.values?.productCommentInCart)
            setProductCommentInCartLabel(cartSettings?.values?.productCommentInCartLabel)
            setShoppingCartText(cartSettings?.values?.continueShoppingText)
            setSubTotalCartFontColor(cartSettings?.values?.subTotalFontColor)
            setCartBubbleQuantity(cartSettings?.values?.cartBubbleQuantity)
            setCartBubbleLineItems(cartSettings?.values?.cartBubbleLineItems)
            if (cartSettings?.values?.daysTopurgeCartitem) {
                setdaysTopurgeCartitem(cartSettings.values.daysTopurgeCartitem)
            }

            const artworkSettings = settingData.find(x => x.page === 'ArtworkSettings')
            setArtworkSetting(artworkSettings?.values?.isArtworkEnabled)
            const orderApprovalSettings = settingData.find(x => x.page === 'OrderApprovalSettings')
            setDefaultOrderApproval(orderApprovalSettings?.values?.defaultApproval)
            setAllowChangeOrderApproval(orderApprovalSettings?.values?.allowChangeOrder)

            const adminSettings = settingData.find(x => x.settingsName === 'adminSettings')
            if(adminSettings && adminSettings.values?.vivaFront) {
                setVivaUrl(adminSettings.values?.vivaFront)
            } else {
                setVivaUrl(VIVA_API_URL)
            }
            if(adminSettings && adminSettings.values?.vivaSessionTime) {
                setVivaSessionTime(adminSettings.values?.vivaSessionTime)
            }
            const paymentList = [];
            paymentList.push({
                paymentType: 'cardconnectSettings',
                value: adminSettings?.values?.creditCardPayment || 'Paid'
            });
            paymentList.push({
                paymentType: 'stripeSettings',
                value: adminSettings?.values?.creditCardPayment || 'Paid'
            });
            paymentList.push({
                paymentType: 'payGateSettings',
                value: adminSettings?.values?.creditCardPayment || 'Paid'
            });
            paymentList.push({
                paymentType: 'heartlandSettings',
                value: adminSettings?.values?.creditCardPayment || 'Paid'
            });
            paymentList.push({
                paymentType: 'giftcardSettings',
                value: adminSettings?.values?.giftCardPayment || 'Paid'
            });
            paymentList.push({
                paymentType: 'budgetSettings',
                value: adminSettings?.values?.budgetPayment
            });
            paymentList.push({
                paymentType: 'directbillSettings',
                value: adminSettings?.values?.directBillPayment || 'Unpaid'
            });
            paymentList.push({
                paymentType: 'pointsSettings',
                value: adminSettings?.values?.pointsPayment || 'Unpaid'
            });
            paymentList.push({
                paymentType: 'purchaseorderSettings',
                value: adminSettings?.values?.purchaseOrderPayment || 'Unpaid'
            });
            paymentList.push({
                paymentType: 'voucherSettings',
                value: adminSettings?.values?.voucherPayment || 'Unpaid'
            });
            setPaymentStatuses(paymentList);

            const orderSettings = settingData.find(x => x.settingsName === 'OrderSettings')
            setShowOrder(typeof orderSettings?.values?.ShowOrder === 'boolean' ? orderSettings.values.ShowOrder : true)
            setShowQuotes(typeof orderSettings?.values?.ShowQuotes === 'boolean' ? orderSettings.values.ShowQuotes : true)
            setShowOrderToApprove(typeof orderSettings?.values?.showOrderToApprove === 'boolean' ? orderSettings.values.showOrderToApprove : true)
        }
    }, [settingData])

    return {
        vivaUrl,
        isPublic,
        storeName,
        orderNotePlaceHolder,
        priceDisplay,
        itempriceDisplay,
        isRequireCostCenter,
        IsRequiredCostCenter,
        disabledOrderSettings,
        documentCheckout,
        defaultCountry,
        textUploadDoc,
        registrationRequired,
        registrationRequiredOptional,
        isRequireCompanyname,
        productLayoutSettings,
        isRequirePhoneNumber,
        renameCompany,
        isBillingSuppress,
        selectShippingAddress,
        selectBillingAddress,
        checkoutSettings,
        subTotalFontColor,
        displayLocation,
        lockCountryEnabled,
        lockCountry,
        lockUserDataFields,
        lockDefaultAddress,
        itemNumber,
        themedefaultColor,
        themedefaultTextColor,
        themedefaulthoverColor,
        showCompareToggle,
        showWishListToggle,
        isShowMyAccount,
        showAddToCartOnCat,
        showAddToCartOnProd,
        showOrderModuleInAccount,
        showLogo,
        logoWidth,
        isordermoduleEnabled,
        requireRegistration,
        daysTopurgeCartitem,
        clrCartButtonText,
        showClrCartButtonText,
        proceedCartText,
        shoppingCartText,
        subTotalCartFontColor,
        DefaultProductBackgroundColor,
        artworkSetting,
        generalSettings,
        title,
        productCommentInCart,
        productCommentInCartLabel,
        defaultApproval,
        cartBubbleQuantity,
        cartBubbleLineItems,
        breadCrumbHoverColor,
        expandSearchBarUnderHeaderMenu,
        showSearchIcon,
        isShowSearchAccount,
        showQuickView,
        showAddToCartOnRelatedProd,
        showQuickViewRelatedProduct,
        showWishListForRelatedProduct,
        defaultFont,
        googleTagManagerId,
        googleMeasurementId,
        allowChangeOrderApproval,
        showSecondaryCostCenter,
        requireSecondaryCostCenter,
        secondaryCostCenterSeparator,
        costCenterLabel,
        secondaryCostCenterLabel,
        showFullListSecondaryCostCenter,
        ShowOrder,
        ShowQuotes,
        showOrderToApprove,
        paymentStatuses,
        sortSizeSetting,
        vivaSessionTime
    }
}
