import { createSelector } from "reselect";

export const selectArtworkList = state => state.productData.artworkList;
export const isProductDataLoading = state => state.productData.loading;
export const productArtworkLoaded = createSelector(
    [
        (state) => state.productData.artworkProductId,
        (_, productId) => productId
    ],
    (artworkProductId, productId) => {
        return artworkProductId === productId;
    }
);