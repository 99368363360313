import {
  FETCH_PRODUCTS_SUCCESS,
  RECEIVE_FEATURED_PRODUCTS,
  IS_LOADING,
  IS_APICOUNT,
  UPDATE_PRODUCT_COLORS,
  RECEIVE_PRODUCTS,
  FETCH_ERROR,
  RECEIVE_TAG_PRODUCTS,
  RECEIVE_CARTWORKS_LIST,
  RECEIVE_CARTWORKS_LIST_BYID,
  RECEIVE_DECORATION_LOCATIONS,
  RECEIVE_DECORATORS_VENDORS_LIST,
  RECEIVE_ART_DECORATORS_TYPE_LIST,
  UPDATE_PRODUCT_TAGS,
  UPDATE_PRODUCT_CATEGORIES,
  UPDATE_PRODUCT_SIZES,
  UPDATE_PRODUCT_QUERY,
  UPDATE_CURRENT_PRODUCT,
  RECEIVE_PRODUCT_STOCK,
  RECEIVE_ART_GLOBAL_IND
} from '../actions/productActions'

const initState = {
  products: [],
  featuredProducts: [],
  tagProducts: [],
  frontProducts: { apiCount: 0 },
  artworkList: [],
  artworkListById: [],
  decorationLocations: [],
  decoratorsVendorsList: [],
  decoratorsTypeList: [],
  productColorsSelected: [],
  productCategoriesSelected: [],
  productTagsSelected: [],
  productSizesSelected: [],
  productQuery: '',
  currentProduct: {},
  apiCount: '0',
  productStock: null,
  artworkGlobalIndicator: false
}

const productReducer = (state = initState, action) => {
  switch (action.type) {
    case RECEIVE_FEATURED_PRODUCTS: {
      return {
        ...state,
        loading: false,
        featuredProducts: action.payload,
        count: action.count,
        meta: action.meta,
      }
    }
    case RECEIVE_DECORATION_LOCATIONS: {
      return {
        ...state,
        loading: false,
        decorationLocations: action.payload.data,
      }
    }
    case RECEIVE_CARTWORKS_LIST: {
      const { artworks, productId } = action.payload;
      return {
        ...state,
        loading: false,
        artworkList: artworks,
        artworkProductId: productId,
      }
    }
    case RECEIVE_CARTWORKS_LIST_BYID: {
      return {
        ...state,
        loading: false,
        artworkListById: action.payload ?? [],
      }
    }
    case RECEIVE_ART_DECORATORS_TYPE_LIST: {
      return {
        ...state,
        loading: false,
        decoratorsTypeList: action.payload,
      }
    }
    case RECEIVE_DECORATORS_VENDORS_LIST: {
      return {
        ...state,
        loading: false,
        decoratorsVendorsList: action.payload,
      }
    }
    case RECEIVE_TAG_PRODUCTS: {
      let data = state.tagProducts
      if (
        action.filters &&
        action.filters.productTagId &&
        action.filters.productTagId != ''
      ) {
        data[action.filters.productTagId] = action.payload
      }
      return {
        ...state,
        loading: false,
        tagProducts: action.payload,
        count: action.count,
        meta: action.meta,
      }
    }
    case RECEIVE_PRODUCTS: {
      return {
        ...state,
        apiCount: action.apiCount,
        frontProducts: {
          products: action.payload,
          count: action.count,
          meta: action.meta || state.frontProducts?.meta,
        }
      }
    }
    case IS_LOADING: {
      return {
        ...state,
        loading: action.loading,
      }
    }
    case IS_APICOUNT: {
      return {
        ...state,
        apiCount: action.apiCount,
      }
    }
    case FETCH_ERROR: {
      return {
        ...state,
        fetchError: true,
      }
    }
    case UPDATE_PRODUCT_COLORS: {
      return {
        ...state,
        productColorsSelected: action.productColorsSelected
      }
    }
    case UPDATE_PRODUCT_TAGS: {
      return {
        ...state,
        productTagsSelected: action.productTagsSelected
      }
    }

    case UPDATE_PRODUCT_CATEGORIES: {
      return {
        ...state,
        productCategoriesSelected: action.productCategoriesSelected
      }
    }
    case UPDATE_PRODUCT_SIZES: {
      return {
        ...state,
        productSizesSelected: action.productSizesSelected
      }
    }
    case UPDATE_PRODUCT_QUERY: {
      return {
        ...state,
        productQuery: action.productQuery
      }
    }
    case UPDATE_CURRENT_PRODUCT: {
      return {
        ...state,
        currentProduct: action.currentProduct
      }
    }
    case RECEIVE_PRODUCT_STOCK: {
      return {
        ...state,
        productStock: action.productStock
      }
    }
    case RECEIVE_ART_GLOBAL_IND: {
      return {
        ...state,
        artworkGlobalIndicator: action.payload
      }
    }
    default:
      return state
  }
}

export default productReducer;
