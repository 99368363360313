import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { getCartItemDisplayTotal } from "../../util/helper";
import { useProductPriceToShow } from "./state/useProductPriceToShow";
import { numberWithCommas2, roundedPrice } from "../../helpers/product";
import CartItemDecoCard from "../../helpers/CartItemDecoCard";

const useStyles = makeStyles((theme) => ({
    decoCardContainer: {
        display: "flex",
        gap: theme.spacing(2)
    }    
}));

function KitItemLine({ cartItem, row, usePointsAsCurrency, productSettings, currency, decimalsToShow, artworkList, decoTypeList, isKitProduct }) {
    let classes = useStyles();
    const {
        getPointsValue
    } = useProductPriceToShow({ productSettings })

    const price = getCartItemDisplayTotal(cartItem, artworkList, productSettings);
    const showPrice = (cartItem.product.isKitEnabled && price > 0) || (cartItem.isKitChildProduct && cartItem.kitPriceMode === 'separate');

    const showColor = typeof cartItem?.product?.showColorProd === 'boolean' ? cartItem?.product?.showColorProd : productSettings?.showColor;
    const showSize = typeof cartItem?.product?.showSizeProd === 'boolean' ? cartItem?.product?.showSizeProd : productSettings?.showSize;
    return (
            <Grid container spacing={8} alignItems="center">
                <Grid item container xs={2} justifyContent="center" alignItems="center">
                    {!!row.image && (
                        <img
                            className="img-fluid"
                            style={{maxWidth: "96px"}}
                            src={row.image}
                            alt={cartItem.product.productName}
                            loading="lazy"
                        />
                    )}
                </Grid>
                <Grid item xs>
                    <Typography variant={isKitProduct ? "h6" : "body1"}>{cartItem.product.productName}</Typography>
                    {showColor && <Typography variant={isKitProduct ? "body1" : "body2"}>Color: {row.color}</Typography>}
                    {showSize && <Typography variant={isKitProduct ? "body1" : "body2"}>Size: {row.size}</Typography>}
                    {(!isKitProduct || cartItem.product.kitChangeQuantity) && <Typography variant={isKitProduct ? "body1" : "body2"}>Quantity: {row.quantity}</Typography>}
                </Grid>
                <Grid item xs>
                    <div className={classes.decoCardContainer}>
                        {row.decorations.map((decoration) => (
                            <CartItemDecoCard 
                                decoration={decoration} 
                                artworkList={artworkList}
                                decoTypeList={decoTypeList}
                            />
                        ))}
                    </div>
                </Grid>
                <Grid item container xs={2} justifyContent="center" alignContent="center" spacing={0}>
                        {showPrice && (
                            <Grid item xs={12}>
                                    {
                                        usePointsAsCurrency ?
                                        getPointsValue(price)
                                        : <Typography variant={"body1"}>
                                            {`${currency?.currencySymbol}
                                            ${productSettings?.priceRoundUp
                                                ? roundedPrice(price, decimalsToShow)
                                                : numberWithCommas2(price, decimalsToShow)}
                                                `}
                                        </Typography>
                                    }
                                </Grid>
                            )
                        }
                        {!showPrice && !isKitProduct && (
                            <Grid item xs={12}>
                                <Typography variant={isKitProduct ? "h6" : "body1"}>-</Typography>
                            </Grid>
                        )}
                </Grid>
            </Grid>
    );
}

export default KitItemLine;