import React, {useContext} from "react"
import { Button, Dialog, DialogContent, DialogTitle, Select, MenuItem, FormControl } from '@material-ui/core';

import { Modal } from "semantic-ui-react"
import { Link } from "react-router-dom"
import {AuthContext} from "../../common/Auth";
//import { URL_SIGN_UP, URL_SIGN_IN, BTN_TEXT_SIGN_UP, BTN_TEXT_SIGN_IN } from "../../util/user-messages"

function NotLoggedInModal({ openM, cancel, expiredSession }) {
    const {
        logout,
    } = useContext(AuthContext)
    return (
        <>
            <Dialog
                fullWidth={true}
                open={openM}
                onClose={cancel}
                className="dialog"
                aria-labelledby="form-dialog-title"
                maxWidth="xs"
            >
                <DialogTitle>
                    <h3>Redirect to Login</h3>
                </DialogTitle>
                <div className="notlogin-form">
                    <div className="notlogin-form-style only-banner" style={{ maxWidth: '' }}>
                        <DialogContent>
                            {
                                expiredSession ? <p>Your session has expired. Please log in again.</p> : <p>
                                    Oh! You need to sign in before doing that...
                                    <br/>
                                    If you don't have an account yet, please sign up.
                                </p>
                            }
                            <div className="buttons-container">
                                <button onClick={cancel} className="cancelButton">Close</button>
                                <button onClick={logout} className="bannerbutton ml-3">Log In</button>
                            </div>
                        </DialogContent>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default NotLoggedInModal
