import React from "react";
import { replaceOrigin } from "../util/helper";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    decoCard: {
        padding: "0.5em",
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
    },
    decoCardTextContainer: {
        textAlign: "center"
    }
}));

function CartItemDecoCard({ decoration, artworkList, decoTypeList }) {
    let classes = useStyles();
    const artwork = artworkList?.filter((artwork) => artwork.id === decoration.artworkID)[0];
    const decoType = decoTypeList?.filter(
        (val) => val.code === artwork?.design?.designType,
    );
    console.log(decoTypeList, artwork?.design?.designType);
    let rowVariation = null
    if (
        artwork &&
        artwork.design.variation &&
        artwork.design.variation.length > 0
    ) {
        rowVariation = artwork.design.variation.filter(
        (variationArray) =>
            variationArray.design_variation_unique_id ==
        decoration.variationUniqID,
        )[0]
    }

    if (!artwork || !decoType || !rowVariation) {
        return <></>;
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <div className={classes.decoCard}>
                    <img
                        className="img-fluid deco-img"
                        src={
                        rowVariation && rowVariation.itemImageThumbnail
                            ? replaceOrigin(rowVariation.itemImageThumbnail)
                            : ''
                        }
                        alt="thumbnail"
                        style={{ maxWidth: '80px', maxHeight: '60px' }}
                    />
                </div>
                <div className={classes.decoCardTextContainer}>
                    <Typography variant="body2"><b>{artwork.identity}</b></Typography>
                    <Typography variant="body2">{decoration.location}</Typography>
                </div>
            </CardContent>
        </Card>
    )
}

export default CartItemDecoCard;